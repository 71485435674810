import React from "react";
import { useSelector } from "react-redux";
import NumberDetail from "../../common/number_detail"
import { approvalAmount, sumEnteredAmount, sumAmount } from "../../texts/fill";

const Details = () => {
  const user = useSelector((state) => state.user)|| JSON.parse(sessionStorage.getItem('user'));;

  return (
    <div style={{ textAlign: 'right' }}>
      <NumberDetail label={approvalAmount} text={user.approvalAmount} />
      <br/>
      <NumberDetail label={sumAmount} text={user.approvalAmount - user.enteredAmount} border={true} />
      <br/>
      <NumberDetail label={sumEnteredAmount} text={user.enteredAmount} border={true}/>
    </div>
  );
};

export default Details;
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { send_file } from "../../texts/fill";
import { useBuildRequest } from "../../hook/use_handle_file";
import { useValidation } from "../../hook/use_validation";
import { saveUser } from "../../context/userReducer"

const SendFileButton = () => {
  const error = useSelector(state => state.error) || JSON.parse(sessionStorage.getItem('error'));
  const file = useSelector(state => state.file) || JSON.parse(sessionStorage.getItem('file'));
  const [load, setLoad] = useState(false);
  const user = useSelector((state) => state.user) || JSON.parse(sessionStorage.getItem('user'));
  const navigate = useNavigate();
  const { calcIfSumAllMatching } = useValidation();
  const { handleErrors, buildRequest } = useBuildRequest();

  const sendFile = () => {
    if (error !== null) {
      alert("בעמודות המסומנות באדום ישנה שגיאה, ולכן הרשומה לא תיקלט. \nיש לתקן את השגיאות בקובץ,.\n לא ניתן לשלוח את הקובץ לעדכון במערכת עד שכל השורות תיהנה תקינות. ")
    }
    else if (calcIfSumAllMatching(file, user)) {
      alert("הסכום הכולל גבוה מהסכום הזמין להכנסה \n לא ניתן לשלוח את הקובץ עד לתיקון הסכום ")
    }
    else if (file.length === 0) {
      alert("קובץ ריק")
    }
    else {
      setLoad(true)
      fetch("https://qa.iyunhaparasha.iyun.co.il/api/" + "partnership/saveFile/" + user.representativeCode + "/" + user.partenshipCode, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(
          buildRequest()
        ),
      })
        .then((res) => res.json())
        .then((data) => {
          setLoad(false)
          user.enteredAmount = data.enteredSum;
          saveUser(user)
          handleErrors(data)
          navigate("/fileResults");
        })
        .catch(() => {
          setLoad(false)
        })
    }
  }

  return (
    <>
      {!load && (
        <span tabindex="0">
          <button
            type="button"
            className="btn btn-primary btn-lg"
            onClick={sendFile}
          >
            <img alt="" src="next.png" style={{ width: '25px', paddingLeft: '5px' }} />
            {send_file}
          </button>
        </span>
      )}
      {load && (
        <button className="btn btn-primary" type="button" disabled>
          <span
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          <div>
            הקובץ בבדיקה
            <br />
            הנתונים מתעדכנים למערכת
          </div>
        </button>
      )}
    </>
  );
};

export default SendFileButton;

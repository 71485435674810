import React from "react";
import { useSelector } from "react-redux";
import { info } from "../../texts/fill"

const ErrorsInfo = () => {
  const error = useSelector(state => state.error);

  return (
    <>
      {error && (<div class="alert alert-danger" role="alert">
        {info}
      </div>)}
    </>
  );
};

export default ErrorsInfo;
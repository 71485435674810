import React from "react";
import Function from "./function";
import { reupload } from "../../texts/fill";
import { useNavigate } from "react-router-dom";
import SendFileButton from "./send_file_button";

const FileFunctions = () => {
  const navigate = useNavigate();

  const reUpload = () => {
    navigate("/fill")
  }

  return (
    <div style={{ display: 'flex' }}>
      <Function />
      <div style={{ display: 'grid', paddingRight: '13px' }}>
        <button
          type="button"
          className="btn btn-outline-primary btn-lg"
          onClick={reUpload}
        >
          <img alt="" src="back.png" style={{ width: '25px', paddingLeft: '5px' }} />
          {reupload}
        </button>
        <br />
        <SendFileButton />
      </div>
    </div>
  );
};

export default FileFunctions;
import { combineReducers } from 'redux';
import { userReducer } from './userReducer';
import { fileReducer } from './fileReducer';
import { errorReducer } from "./errorReducer";
import { listsReducer } from "./listsReducer";
import { partnershipsReducer } from './partnershipsReducer';

const rootReducer = combineReducers({
    user: userReducer,
    file: fileReducer,
    error: errorReducer,
    lists: listsReducer,
    partnerships: partnershipsReducer,
});

export default rootReducer;

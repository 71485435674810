import React from "react";
import FileFunctions from "./file_functions"
import Details from "./details"
import ErrorsInfo from "./error_info";

const FileHeader = () => {
  return (
    <div className="navbar navbar-expand-lg navbar-light bg-light"
      style={{ position: 'sticky', top: '0', display: 'flex', justifyContent: 'space-between', padding: '11px 23px 0 23px', height: '184px' }}>
      <Details />
      <ErrorsInfo/>
      <FileFunctions />
    </div>
  );
};

export default FileHeader;
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import {
    last_name,
    first_name,
    street,
    num,
    ishuv,
    phone,
    mobilePhone,
    id,
    colel,
    children_num,
    sum, bankNum,
    bankBranchNum,
    bankAccount,
    food_chain,
    comments,
    exists_card,
    exists_card_2
} from "../common/statics"
import { updateProperty } from "../context/fileReducer";
import { saveLists } from "../context/listsReducer";
import { useEffect } from 'react';

export const useBuildRequest = () => {
    const dispatch = useDispatch();
    const file = useSelector(state => state.file) || JSON.parse(sessionStorage.getItem('file'));
    const lists = useSelector(state => state.lists) || JSON.parse(sessionStorage.getItem('lists'));
    const user = useSelector(state => state.user) || JSON.parse(sessionStorage.getItem('user'));

    useEffect(() => {
        if (!lists) {
            loadLists()
        }
    }, []);

    const buildRequest = () => {
        const body = []
        file.forEach((row) => {
            body.push(convertObject(row))
        })
        return body;
    }

    const convertObject = (row) => {
        const codeIshuv = getCode(row[ishuv]?.trim(), lists.codeIshuv)
        const codeMosad = getCode(row[colel]?.trim(), lists.codeMosad)
        const foodChainCode = getCode(row[food_chain]?.trim() || user.foodChain, lists.codeFoodChain)

        return {
            tz: Number(replaceWrongChars(row[id].toString())),
            lastName: replaceStringEmptyWithNull(replaceWrongChars(row[last_name])),
            firstName: replaceStringEmptyWithNull(replaceWrongChars(row[first_name])),
            street: replaceStringEmptyWithNull(replaceWrongChars(row[street])),
            streetNum: replaceNumberEmptyWithNull(row[num]),
            codeIshuv: replaceNumberEmptyWithNull(codeIshuv),
            newIshuv: codeIshuv === null ? replaceStringEmptyWithNull(replaceWrongChars(row[ishuv])) : null,
            phoneNum: replaceWrongChars(replaceStringEmptyWithNull(row[phone]?.toString())),
            mobilePhoneNum: replaceWrongChars(replaceStringEmptyWithNull(row[mobilePhone]?.toString())),
            codeMosad: replaceNumberEmptyWithNull(codeMosad),
            newMosad: codeMosad === null ? replaceStringEmptyWithNull(replaceWrongChars(row[colel])) : null,
            childrenNum: replaceNumberEmptyWithNull(row[children_num]),
            foodChainCode: foodChainCode,
            bankNum: Number(replaceWrongChars(row[bankNum]?.toString())),
            bankBranchNum: Number(replaceWrongChars(row[bankBranchNum]?.toString())),
            bankAccount: Number(replaceWrongChars(row[bankAccount]?.toString())),
            milgaSum: parseInt(row[sum]?.toString().trim()),
            idishCard: replaceStringEmptyWithNull(replaceWrongChars(row[exists_card]?.toString())),
            idishCard2: replaceStringEmptyWithNull(replaceWrongChars(row[exists_card_2]?.toString()))
        };
    }

    const getCode = (value, list) => {
        for (const val of list) {
            if (val.name === value) {
                return val.code;
            }
        }
        return null;
    }

    const handleErrors = (data) => {
        file.forEach((row, index) => {
            dispatch(updateProperty(index, comments, data.results[replaceWrongChars(row[id].toString()).replace(/^0+/, '')]));
        })
        sessionStorage.setItem('EnteredRows', data.enteredRows);
        sessionStorage.setItem('FailedRows', data.failedRows);
    }

    const replaceNumberEmptyWithNull = (field) => {
        if (field === "" || field === null || field === undefined) {
            return null;
        }
        return Number(field.toString().trim())
    }

    const replaceStringEmptyWithNull = (field) => {
        if (field === "" || field === null || field === undefined) {
            return null;
        }
        return field.trim();
    }

    const replaceWrongChars = (field) => {
        if (field !== undefined) {
            return field?.trim().replaceAll("'", "").replaceAll('"', "").replaceAll("-", "").replaceAll(",", "");
        }

        else return field;
    }

    const loadLists = async () => {
        fetch("https://parasha.iyun.co.il/api/Help/getAllCodes/1", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((res) => res.json())
            .then((data) => {
                dispatch(saveLists(data));
                sessionStorage.setItem('lists', JSON.stringify(data));
            })
    }

    return { handleErrors, buildRequest }
}
import { useSelector } from 'react-redux';
import { food_chain, REQUIRED_FIELDS, sum, mobilePhone, phone, INT_FIELDS, CSV_HEADERS, FIELD_TO_MAX_CHARS } from "../common/statics";

export const useValidation = () => {
    const file = useSelector(state => state.file) || JSON.parse(sessionStorage.getItem('file'));
    const user = useSelector(state => state.user) || JSON.parse(sessionStorage.getItem('user'));

    const validateColumn = (columnName, columnValue, row) => {
        const errors = [];
        let phoneOrMobilephoneExist = true;
        switch (columnName) {
            case sum:
                const sumForEachOneResult = isSumForEachOneMatching(columnValue);
                if (sumForEachOneResult !== true)
                    errors.push(sumForEachOneResult);
                break;
            case food_chain:
                if (user.foodChain !== null) {
                    const foodChainResult = foodChainExist(columnValue);
                    if (foodChainResult !== true)
                        errors.push(foodChainResult);
                }
                break;
            case mobilePhone:
                phoneOrMobilephoneExist = isPhoneOrMobilephoneExist(row);
                if (phoneOrMobilephoneExist !== true)
                    errors.push(phoneOrMobilephoneExist);
                break;
            case phone:
                phoneOrMobilephoneExist = isPhoneOrMobilephoneExist(row);
                if (phoneOrMobilephoneExist !== true)
                    errors.push(phoneOrMobilephoneExist);
                break;
            default:
                break;
        }

        const requiredFieldsResult = isRequiredFieldAndExist(columnName, columnValue);
        if (requiredFieldsResult !== true)
            errors.push(requiredFieldsResult);

        const columnTypeMatching = isColumnTypeMatching(columnName, columnValue);
        if (columnTypeMatching !== true)
            errors.push(columnTypeMatching);

        const lengthMatching = isLengthMatching(columnName, columnValue);
        if (lengthMatching !== true)
            errors.push(lengthMatching);

        return errors;
    }

    const isLengthMatching = (columnName, columnValue) => {
        const columnToMaxChars = FIELD_TO_MAX_CHARS.find(item => columnName in item);
        if (columnToMaxChars) {
            const max = columnToMaxChars[columnName];
            const maxOk = shortThenMaxChars(columnValue, max)
            if (maxOk !== true)
                return maxOk;
        }
        return true;
    }

    const isSumAllMatching = (rows, newValue) => {
        const calcSum = rows.length * newValue;
        return calcSum < (user.approvalAmount - user.enteredAmount);
    }

    const calcIfSumAllMatching = () => {
        const totalSum = calculateTotalSum(file);
        return totalSum > (user.approvalAmount - user.enteredAmount);
    }

    const calculateTotalSum = () => {
        let totalSum = 0;
        file.forEach((item) => {
            totalSum += Number(item[sum]) || 0;
        });
        return totalSum;
    };

    const isColumnTypeMatching = (columnName, columnValue) => {
        if (INT_FIELDS.includes(columnName)) {
            if (columnValue !== "" && !(/^\d+$/.test(columnValue))) {
                return "שדה יכול להכיל מספרים בלבד!";
            }
        }
        return true;
    }

    const isRowsExists = (rows) => {
        return rows.length > 0;
    }

    const isSumForEachOneMatching = (columnValue) => {
        if (user.amountLimitForEach !== null && columnValue > user.amountLimitForEach) {
            return "סכום גבוה מהסכום שאושר"
        }
        return true
    }

    const shortThenMaxChars = (columnValue, maxChars) => {
        if (columnValue?.length > maxChars) {
            return "מקסימום " + maxChars + " תווים";
        }
        return true
    }

    const isRequiredFieldAndExist = (columnName, columnValue) => {
        const requiredFields = REQUIRED_FIELDS(user);
        if (requiredFields.includes(columnName)) {
            if (columnValue === "") {
                return "שדה חובה!";
            }
        }
        return true
    }

    const isPhoneOrMobilephoneExist = (row) => {
        if (isEmptyColumn(mobilePhone, row) && isEmptyColumn(phone, row)) {
            return "חובה למלא טלפון או פלאפון";
        }
        return true
    }

    const isEmptyColumn = (column, row) => {
        const a = row[column] === "" || row[column] === undefined;
        return a
    }

    const foodChainExist = (columnValue) => {
        if (user.foodChain === null && columnValue === "") {
            return "שדה חובה!"
        }
        return true;
    }

    const isColumnsMatched = () => {
        return file && Object.keys(file?.[0]).every(key => CSV_HEADERS.includes(key));
    }

    return { validateColumn, isSumAllMatching, calcIfSumAllMatching, calculateTotalSum, isColumnTypeMatching, isRowsExists, isColumnsMatched }

}
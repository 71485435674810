import { utils, writeFile } from "xlsx";
import { CSV_HEADERS } from "../common/statics";

export const useExportFile = () => {
    const handleExport = () => {
        const headings = [CSV_HEADERS]
        const wb = utils.book_new();
        const ws = utils.json_to_sheet([]);
        utils.sheet_add_aoa(ws, headings);
        utils.sheet_add_json(ws, [], { origin: 'A2', skipHeader: true });
        utils.book_append_sheet(wb, ws, 'Report');
        writeFile(wb, 'קובץ שותפויות.xlsx');
    }

    return { handleExport }
}
import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { choose_text, choose_button } from "../texts/login";
import UserDetails from "../upload_file/user_details";
import { saveUser } from "../context/userReducer";

const Choose = () => {
    const partnerships = useSelector((state) => state.partnerships);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const chooseP = (index) => {
        sessionStorage.setItem('user', JSON.stringify(partnerships[index]))
        dispatch(saveUser(partnerships[index]));
        navigate("/fill");
    }

    return (
        <div style={{padding: '40px'}}>
            <div style={{
               marginBottom: '40px', marginRight: '20%',
                fontSize: '25px',
                width: '60%'
            }} class="alert alert-success" role="alert">
                {choose_text}
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-around' }} >
                {partnerships.map((p, index) =>
                    <div style={{ padding: '34px', border: '1px solid', borderRadius: '34px' }} >
                        <UserDetails user={p} showButton={false} />
                        <div style={{ paddingBottom: '30px' }} />
                        <button style={{ marginRight: '300px' }} className="btn btn-primary" onClick={() => chooseP(index)}>{choose_button}</button>
                    </div>
                )}
            </div>
        </div>)

};

export default Choose;
export const SAVE_PARTNERSHIP = 'SAVE_PARTNERSHIP';

export const savePartnerships = (partnerships) => {
    return {
        type: SAVE_PARTNERSHIP,
        payload: partnerships,
    };
};

const initialState = null;

export const partnershipsReducer = (state = initialState || JSON.parse(sessionStorage.getItem('partnerships')), action) => {
    switch (action.type) {
        case SAVE_PARTNERSHIP:
            return action.payload;
        default:
            return state;
    }
};

const initialState = null;

export const SAVE_FILE = 'SAVE_FILE';

export const saveFile = (file) => {
    return {
        type: SAVE_FILE,
        payload: file,
    };
};

export const UPDATE_PROPERTY_FOR_ALL = 'UPDATE_PROPERTY_FOR_ALL';

export const updatePropertyForAll = (propertyName, newValue) => {
    return {
        type: UPDATE_PROPERTY_FOR_ALL,
        payload: { propertyName, newValue },
    };
};
export const UPDATE_PROPERTY = 'UPDATE_PROPERTY';

export const updateProperty = (index, propertyName, newValue) => {
    return {
        type: UPDATE_PROPERTY,
        payload: { index, propertyName, newValue },
    };
};

export const fileReducer = (state = initialState || JSON.parse(sessionStorage.getItem('file')), action) => {
    switch (action.type) {
        case SAVE_FILE:
            sessionStorage.setItem('file', JSON.stringify(action.payload))
            return action.payload;
        case UPDATE_PROPERTY:
            const { index, propertyName, newValue } = action.payload;
            const updatedData = [...state];
            updatedData[index][propertyName] = newValue;
            sessionStorage.setItem('file', JSON.stringify(updatedData))
            return updatedData;
        case UPDATE_PROPERTY_FOR_ALL:
            const { propertyName: propName, newValue: propValue } = action.payload;
            const updatedAllData =  state.map((item) => ({
                ...item,
                [propName]: propValue,
            }));
            sessionStorage.setItem('file', JSON.stringify(updatedAllData))
            return updatedAllData;
        default:
            return state;
    }
};

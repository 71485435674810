import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { saveError } from "../context/errorReducer";
import { useDrawFile } from "../hook/use_draw_file";
import { useDrawRow } from "../hook/use_draw_row";

const Body = () => {
    const file = useSelector(state => state.file) || JSON.parse(sessionStorage.getItem('file'));
    const dispatch = useDispatch();
    const [currentIndex, setCurrentIndex] = useState(null);
    const { getRemoveText, removeLine } = useDrawFile();
    const { getRow } = useDrawRow();

    useMemo(() => {
        dispatch(saveError(null))
        sessionStorage.setItem('error', JSON.stringify(null))
    }, [file])

    return (
        <tbody>
            <div className="modal fade" id="exampleModal" tabindex="-1" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">
                            <h5 className="" id="exampleModalLabel">{getRemoveText(currentIndex)}</h5>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => removeLine(currentIndex)}>אישור</button>
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">ביטול</button>
                        </div>
                    </div>
                </div>
            </div>
            {file.map((row, index) =>
                <tr key={row[0] + index}>
                    {getRow(row, index)}
                    <td
                        onClick={() => setCurrentIndex(index)}
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal">
                        <img alt="" src="remove.png" style={{ height: '20px' }} />
                    </td>
                </tr>
            )}
        </tbody>
    )
};

export default Body;
const initialState = null;
export const SAVE_ERROR = 'SAVE_ERROR';

export const saveLists = (error) => {
    return {
        type: SAVE_ERROR,
        payload: error,
    };
};

export const listsReducer = (state = initialState|| JSON.parse(sessionStorage.getItem('error')), action) => {
    switch (action.type) {
        case SAVE_ERROR:
            return action.payload;
        default:
            return state;
    }
};

import React from "react";
import Details from "./details"
import { useNavigate } from "react-router-dom";
import { reupload } from "../../texts/fill";
import ErrorAlert from "../../common/errorAlert";

const FileHeader = () => {
  const navigate = useNavigate();
  const error = sessionStorage.getItem('FailedRows');
  
  const reUpload = () => {
    navigate("/fill")
  }

  return (
    <div className="navbar navbar-expand-lg navbar-light bg-light"
      style={{ position: 'sticky', top: '0', display: 'flex', justifyContent: 'space-between', padding: '11px 23px 0 23px', height: '184px' }}>
      <Details />
      {error > 0 && <ErrorAlert msg={"השורות המסומנות באדום לא נכנסו לשותפות"}/>}
      <button
          type="button"
          className="btn btn-outline-primary btn-lg"
          onClick={reUpload}
        >
          <img alt="" src="back.png" style={{ width: '25px', paddingLeft: '5px'}} />
          {reupload}
        </button>
    </div>
  );
};

export default FileHeader;
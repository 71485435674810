import { Routes, Route } from 'react-router-dom';
import Login from "./login/login";
import FillFile from "./upload_file/fill_file";
import DrawFile from "./draw_file/draw_file";
import DrawFileResult from "./draw_file_results/draw_file_results";
import Choose from './choose/choose';

const App = () => {
    return (
        <>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/fill" element={<FillFile />} />
                <Route path="/uploadedFile" element={<DrawFile />} />
                <Route path="/fileResults" element={<DrawFileResult />} />
                <Route path="/choose" element={<Choose />} />
            </Routes>
        </>
    );
};

export default App;
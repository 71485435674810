import { comments, first_name, last_name, id } from "../common/statics";
import { saveFile } from "../context/fileReducer";
import { useDispatch, useSelector } from 'react-redux';
import { saveLists } from "../context/listsReducer";

export const useDrawFile = () => {
    const file = useSelector(state => state.file) || JSON.parse(sessionStorage.getItem('file'));;
    const dispatch = useDispatch();


    const sortByTz = () => {
        const newData = [...file];
        newData.sort((a, b) => a[id] - b[id]);
        dispatch(saveFile(newData));
    }

    const sortByName = () => {
        const newData = [...file];
        newData.sort((a, b) => {
            const nameA = b[last_name].toUpperCase();
            const nameB = a[last_name].toUpperCase();
            if (nameA < nameB) {
                return 1;
            }
            if (nameA > nameB) {
                return -1;
            }
            return 0;
        });
        dispatch(saveFile(newData));
    }


    const removeLine = (index) => {
        const newData = [...file];
        newData.splice(index, 1);
        dispatch(saveFile(newData));
    }

    const getRemoveText = (index) => {
        return "האם אתה בטוח כי ברצונך למחוק את " + file?.[index]?.[first_name] + " " + file?.[index]?.[last_name] + "?";
    }

    const getClassName = (row) => {
        if (row[comments] !== undefined && row[comments] !== "מנוי נוסף בהצלחה") {
            return "table-danger"
        }
        if (row[comments] === "מנוי נוסף בהצלחה") {
            return "table-success"
        }
        return ""
    }

    return { removeLine, getClassName, getRemoveText, sortByTz, sortByName }
}

import React from "react";

const NumberDetail = ({ text, label, border }) => {
    const className = border ? "border border-secondary rounded" : "";
    return (<div className={className} style={{ width: 'max-content' }}>
        <div style={{ display: 'inline-flex' }}>
            <div style={{ fontWeight: 'bold', whiteSpace: 'pre' }}>{label}</div>
            <div type="number">{text?.toLocaleString()}</div>
            <div className="mx-1">ש"ח</div>
        </div>
    </div>)
}

export default NumberDetail;
export const details = "פרטי שותף ושותפות";
export const currentMilga = "מלגה נוכחית: ";
export const approvalAmount = "סכום שאושר: ";
export const enteredAmount = "סכום שהוכנס: ";
export const sumEnteredAmount = "סה''כ סכום שהוכנס: ";
export const rowsNum = "שורות בקובץ: ";
export const rowsSumNum = 'סה"כ סכום כולל בקובץ: ';
export const sumAmount = 'סה"כ סכום זמין להכנסה: ';
export const amountLimit = "הגבלת סכום לאברך: ";
export const foodChain = "רשת מזון: ";
export const upload = "העלאת קובץ";
export const upload_button = "העלה";
export const exm_file = "לחצו כאן - להורדת קובץ ריק לדוגמא";
export const upload_file = "לחצו כאן - להעלאת קובץ מלא";
export const empty_file = "הקובץ ריק";
export const high_sum = "הסכום הכולל גבוה מהסכום שנקבע";
export const update_sum = "עדכון סכום לכולם";
export const update_colel = "עדכון כולל לכולם";
export const reupload = "העלאת קובץ חדש";
export const send_file = "שמירת ושליחת הקובץ";
export const tooltip = "לא כל השדות תקינים";
export const currency = " שח";
export const warning = "יש להעלות קובץ עם שמות עמודות זהות בדיוק לקובץ הדוגמא.\n מומלץ להוריד, למלא ולהעלות את קובץ לדוגמא ";
export const warning_2 = "משתמשים יקרים";
export const warning_3 = "אנא ואנא לפני כל שותפות חדשה אנא הורידו קובץ *חדש* והעלו אותו מלא, העמודות מתעדכנות מידי פעם וזה גורם לבעיות בקליטת קבצים מגרסאות קומות.";
export const info = "כדי לראות את השגיאה בשדות שגויים - יש להניח את העכבר על השדה"


import React from "react";
import { useDispatch } from 'react-redux';
import { CSV_HEADERS,exists_card, exists_card_2 } from "../common/statics";
import { saveError } from "../context/errorReducer";
import { updateProperty } from "../context/fileReducer";
import { INT_FIELDS } from "../common/statics";
import { useValidation } from "./use_validation";

export const useDrawRow = () => {
    const dispatch = useDispatch();
    const { validateColumn } = useValidation();

    const getRow = (originalRow, index) => {
        const row = createRow(originalRow, index)

        return Object.entries(row).map(([columnName, columnValue]) => {
            const errors = validateColumn(columnName, columnValue, row);
            const errorsExists = errors.length > 0;
            if (errorsExists) {
                dispatch(saveError(true));
                sessionStorage.setItem('error', JSON.stringify(true))
            }

            const finalStyle = getStyle(errorsExists);
            return (
                <td style={finalStyle} key={index + columnName}>
                    <div data-toggle="tooltip"
                        title={errors}
                        style={{ display: 'flex', width: '51px' }}>
                        {getInputColumn(columnValue, index, columnName, errorsExists)}
                        {errorsExists &&
                            <img src="searching.png" alt="" style={{ width: '30px' }} />}
                    </div>
                </td >
            )
        })
    }

    const getInputColumn = (columnValue, index, columnName, errorsExists) => {
        return <input
            type="text"
            value={columnValue?.trim()}
            style={{ width: errorsExists ? '70px' : '82px', border: 'white', borderRadius: '4px', height: '37px' }}
            onChange={(e) => handleEdit(index, columnName, e.target.value)}
        />
    }

    const handleEdit = (index, field, value) => {
        dispatch(updateProperty(index, field, value));
    };

    const getStyle = (errorsExists) => {
        const padding = { padding: '1px' }
        const finalStyle = errorsExists ? { backgroundColor: '#F38383' } : "";
        return { ...padding, ...finalStyle }
    }

    const createRow = (row, index) => {
        let newRow = {}
        const keys = Object.keys(row)
        for (let i = 0; i < CSV_HEADERS.length; i++) {
            const headersKey = CSV_HEADERS[i]
            if (keys.includes(headersKey)) {
                newRow[headersKey] = row[headersKey]?.toString()
            }
            else {
                const updatedKeys = keys.filter(item => item !== 'מספר');
                const fkeys = updatedKeys.map(item => item.replace('מספר', ''));
                const keyHeader = fkeys.find(item => headersKey !== exists_card && headersKey !== exists_card_2 && headersKey.includes(item))
                if (keyHeader) {
                    newRow[headersKey] = row['מספר' + keyHeader]?.toString()
                    dispatch(updateProperty(index, headersKey, row[keyHeader]));
                }
                else newRow[headersKey] = "";
            }
            if (INT_FIELDS.includes(headersKey)) {
                newRow[headersKey] = newRow[headersKey]?.toString().replaceAll("'", "").replaceAll('"', "").replaceAll("-", "").replaceAll(",", "");
            }
        }
        return newRow
    }
    return { getRow };
}
import React from "react";
import { useSelector } from "react-redux";
import UserDetails from "./user_details";
import FileHandler from "./file_handler";

const FillFile = () => {
  const user = useSelector((state) => state.user) || JSON.parse(sessionStorage.getItem('user'));;
  return (
    <div className="card container">
      <UserDetails user={user} />
      <br />
      <FileHandler user={user} />
    </div>
  );
};

export default FillFile;
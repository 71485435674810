import React from "react";
import { TABLE_HEADERS } from "../common/statics";

const Headers = () => {
    return (
        <thead style={{ position: 'sticky', top: '184px' }}>
            <tr>
                {TABLE_HEADERS.map(header => (
                    <th scope="col"><div style={{ whiteSpace: 'pre' , width:'51px'}}>{header}</div></th>
                ))}
            </tr>
        </thead>
    )
};

export default Headers;
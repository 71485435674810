import React from "react";
import { amountLimit, approvalAmount, currentMilga, enteredAmount, foodChain, details, sumAmount } from "../texts/fill";
import Detail from "../common/detail";
import NumberDetail from "../common/number_detail"
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { return_choose } from "../texts/login";

const UserDetails = ({ user, showButton = true }) => {
    const navigate = useNavigate();
    const partnerships = useSelector((state) => state.partnerships);

    return (
        <div className="card-body" style={{ textAlign: 'right', padding: '20px' }}>
            <h3 className="display-5 font-weight-bold">{showButton ? details : user.currentMilga}</h3>
            <hr className="my-4" />
            {partnerships &&
                showButton &&
                <button className="btn btn-primary" style={{ position: 'absolute', left: '3%' }} onClick={() => navigate("/choose")}>{return_choose}</button>}
            <h4 className="font-weight-bold">{user.name}</h4>
            <h5 className="display-7">{user.area}</h5>
            <br />
            <Detail label={currentMilga} text={user.currentMilga} />
            {!!user.foodChain && <Detail label={foodChain} text={user.foodChain} />}
            {!!user.amountLimitForEach && <NumberDetail label={amountLimit} text={user.amountLimitForEach} />}
            <NumberDetail label={approvalAmount} text={user.approvalAmount} />
            <NumberDetail label={enteredAmount} text={user.enteredAmount} />
            {<NumberDetail label={sumAmount} text={user.approvalAmount - user.enteredAmount} border={true} />}
        </div>
    )
};


export default UserDetails;
import React from "react";
import { RESULT_TABLE_HEADERS } from "../common/statics";

const Headers = () => {
    return (
        <thead style={{ padding:'3px' }}>
            <tr>
                {RESULT_TABLE_HEADERS.map(header => (
                    <th scope="col"><div style={{ whiteSpace: 'pre', width: '65px', textAlign: 'end',  direction: 'ltr' }}>{header}</div></th>
                ))}
            </tr>
        </thead>
    )
};

export default Headers;
import React, { useState } from "react";
import { failedRows, successRows } from "../texts/results"

const ResultsCard = () => {
    const [showModal, setShowModal] = useState(true);
    const enteredRows = sessionStorage.getItem('EnteredRows');
    const error = sessionStorage.getItem('FailedRows');
    return (
        <div>
            {showModal && (
                <div className="card">
                    <div class="card-body">
                        <div style={{ paddingBottom: '30px' }}>
                            <h3 className="pt-5">הקובץ נקלט בהצלחה</h3>
                            <img src="checked.png" alt="" className="pt-5" style={{ width: '100px' }} />
                            <div className="pt-5">{successRows}: {enteredRows}</div>
                            {error > 0 &&
                                <div className="pt-5">{failedRows}: {error}</div>
                            }
                        </div>
                        <button type="button" class="btn btn-secondary" onClick={() => setShowModal(false)}>אישור</button>
                    </div>
                </div>)}
        </div>
    )
};

export default ResultsCard;
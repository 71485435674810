import React, { useState } from "react";
import { read, utils } from "xlsx";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import DownloadExample from "./download_example";
import { empty_file, upload_file } from "../texts/fill";
import { saveFile } from "../context/fileReducer";
import { saveError } from "../context/errorReducer";
import { useValidation } from "../hook/use_validation";

const FileHandler = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [error, setError] = useState(false);
    const acceptFileTypes = ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel";
    const [load, setLoad] = useState(false);
    const { isRowsExists } = useValidation();

    const handleImport = ($event) => {
        dispatch(saveError(null))
        sessionStorage.setItem('FailedRows', null);
        sessionStorage.setItem('error', JSON.stringify(null))
        let newValue;
        if ($event) {
            const files = $event.target.files;
            if (files.length) {
                setLoad(true);
                const file = files[0];
                const reader = new FileReader();
                reader.onload = (event) => {
                    const wb = read(event.target.result);
                    const sheets = wb.SheetNames;
                    if (sheets.length) {
                        newValue = utils.sheet_to_json(wb.Sheets[sheets[0]]);
                        if (isRowsExists(newValue)) {
                            dispatch(saveFile(newValue));
                            navigate("/uploadedFile")
                        } else { setError(true); setLoad(false); }
                    }
                }
                reader.readAsArrayBuffer(file);
            }

        }
    }

    return (
        <div>
            <div className="card p-3" 
            style={{ display: 'grid', gridTemplateColumns: '619px 218px 424px' , backgroundColor: 'darkgray'}}>
                <DownloadExample />
                <div></div>
                <button className="btn btn-light m-5" style={{ height: '85px', width: '350px' }}>
                    {upload_file}
                    <input type="file" onChange={e => handleImport((e))} style={{ paddingRight: '66px' }}
                        accept={acceptFileTypes} />
                    {load &&
                        <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                        />
                    }
                </button>
                <div></div>
                <div></div>
                {error && <div className="alert alert-danger" role="alert">
                    {empty_file}
                </div>}
            </div>

        </div>
    )
};

export default FileHandler;

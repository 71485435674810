import React from "react";
import { useSelector } from 'react-redux';
import { id } from "../common/statics";
import { useDrawFile } from "../hook/use_draw_file";
import { useDrawResultRow } from "../hook/use_draw_results_row";

const Body = () => {
    const file = useSelector(state => state.file);
    const { getClassName } = useDrawFile();
    const { getRow } = useDrawResultRow();

    return (
        <tbody>
            {file.map((row, index) =>
                <tr key={row[id] + index} className={getClassName(row)}>
                    {getRow(row)}
                </tr>
            )}
        </tbody>
    )
};

export default Body;
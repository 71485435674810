import React, { useState } from "react";
import { useDispatch } from 'react-redux';
import { update_sum, update_colel } from "../../texts/fill";
import { sum, colel } from "../../common/statics";
import { updatePropertyForAll } from "../../context/fileReducer";
import { useDrawFile } from "../../hook/use_draw_file";

const Function = () => {
    const dispatch = useDispatch();
    const [sumValue, setSumVaule] = useState();
    const [colelValue, setColelVaule] = useState();
    const { sortByTz, sortByName } = useDrawFile();

    return (
        <>
            <div style={{ display: 'grid', gridColumnGap: '6px', gridTemplateColumns: '143px 153px', rowGap: '6px' }}>
                <input
                    className="form-control"
                    value={sumValue}
                    onChange={e => setSumVaule(Number(e.target.value))}
                    type={"number"} />
                <button
                    className="btn btn-outline-dark"
                    onClick={() => dispatch(updatePropertyForAll(sum, sumValue))}
                >{update_sum}</button>
                <input
                    className="form-control"
                    value={colelValue}
                    onChange={e => setColelVaule(e.target.value)}
                    type={"text"} />
                <button
                    className="btn btn-outline-dark"
                    onClick={() => dispatch(updatePropertyForAll(colel, colelValue))}>{update_colel}</button>
            </div>
            <div style={{ display: 'grid', rowGap: '6px', paddingRight: '9px' }}>
                <button className="btn btn-outline-success" onClick={() => sortByName()}>מיון לפי שם משפחה</button>
                <button className="btn btn-outline-success" onClick={() => sortByTz()}>מיון לפי ת.ז.</button>
            </div>
        </>
    )
};

export default Function;
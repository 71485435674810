import React from "react";
import { RESULT_CSV_HEADERS, comments, INT_FIELDS } from "../common/statics";

export const useDrawResultRow = () => {

    const getRow = (originalRow) => {
        const row = createRow(originalRow)
        return  Object.entries(row).map(([columnName, columnValue]) => {
            const width = columnName === comments ? '82px' : '56px';
            return (
                <td>
                    <div
                        style={{ width: width, border: 'white', borderRadius: '4px', height: '37px' }}
                    >{columnValue}
                    </div>
                </td >
            )
        })
    }

    const createRow = (row) => {
        let newRow = {}
        const keys = Object.keys(row)
        for (let i = 0; i < RESULT_CSV_HEADERS.length; i++) {
            const headersKey = RESULT_CSV_HEADERS[i]
            if (keys.includes(headersKey)) {
                newRow[headersKey] = row[headersKey]
                if (INT_FIELDS.includes(headersKey)) {
                    newRow[headersKey] = newRow[headersKey].toString().replaceAll("'", "").replaceAll('"', "").replaceAll("-", "").replaceAll(",", "");
                }
            }
            else newRow[headersKey] = ""
        }
        return newRow
    }

    return { getRow };
}
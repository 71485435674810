import React from "react";
import { useSelector } from "react-redux";
import NumberDetail from "../../common/number_detail"
import Detail from "../../common/detail"
import WarningAlert from "../../common/warning_alert";
import { approvalAmount, enteredAmount, sumAmount, rowsNum, rowsSumNum } from "../../texts/fill";
import { high_sum } from "../../texts/fill"
import { useValidation } from "../../hook/use_validation";

const Details = () => {
  const user = useSelector((state) => state.user) || JSON.parse(sessionStorage.getItem('user'));
  const file = useSelector((state) => state.file) || JSON.parse(sessionStorage.getItem('file'));
  const { calcIfSumAllMatching, calculateTotalSum } = useValidation();

  return (
    <div style={{ textAlign: 'right' }}>
      <Detail label={`${user.currentMilga} | ${user.name}`} />
      <NumberDetail label={approvalAmount} text={user.approvalAmount} />
      <NumberDetail label={enteredAmount} text={user.enteredAmount} />
      <NumberDetail label={sumAmount} text={user.approvalAmount - user.enteredAmount} border={true} />
      <Detail label={rowsNum} text={file.length} />
      <NumberDetail label={rowsSumNum} text={calculateTotalSum()} border={true} />
      {calcIfSumAllMatching() && <WarningAlert msg={high_sum} />}
    </div>
  );
};

export default Details;
import React from "react";
import FileHeader from "./top/file_header";
import Headers from "./headers"
import Body from "./body"
import ResultsCard from "./results_card";

const DrawFileResult = () => {
    return (
        <div>
            <ResultsCard />
            <FileHeader />
            <div className="p-3">
                <table className="table table-hover" style={{ fontSize: '13px' }}>
                    <Headers />
                    <Body />
                </table>
            </div>
        </div>
    )
};

export default DrawFileResult;